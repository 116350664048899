<template>
  <transition name="fade">
    <div v-if="show" class="modal-shadow" @click.self="closeModal">
      <div class="modal-presale">
        <loading
          v-model:active="loading"
          :can-cancel="false"
          :is-full-page="false"
          :opacity="0.1"
          loader="bars"
          color="#8222ca"
        />
        <slot name="title">
          <h3 class="modal--title">Here you can Buy CTRL tokens</h3>
          <button class="modal_close" @click="closeModal">
            <img src="images/cancel.svg" />
          </button>
        </slot>
        <slot name="body">
          <div class="info modal--content mt-4">
            The price is 1 CTRL = {{ info.out2InExchangePrice.toString() }} XTZ
          </div>
          <small class="d-block mt-4">The most popular amounts:</small>
          <div class="d-flex sp justify-content-between mt-4 mb-5">
            <div class="select-sum" v-for="mainSum in sums" :key="mainSum">
              <input
                type="radio"
                :value="mainSum.sumId"
                :id="mainSum.sumId"
                v-model="sum"
                @change="check($event)"
              />
              <label class="button-medium d-inline" :for="mainSum.sumId">
                {{ mainSum.sumId }}
              </label>
            </div>
          </div>
        </slot>
        <slot name="footer">
          <div
            class="
              modal--footer
              mt-3
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="col-6">
              <input
                class="p-2"
                type="number"
                v-model="sum"
                placeholder="12000"
              />
            </div>
            <div class="col-6 d-flex justify-content-end">
              <input
                class="button-main"
                type="submit"
                @click="buy"
                value="Buy tokens"
              />
            </div>
          </div>
          <div v-if="loading" class="loading d-flex mt-4">
            <svg class="circular-loader" viewBox="25 25 50 50">
              <circle
                class="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#70c542"
                stroke-width="2"
              />
            </svg>
            <small class="d-flex ms-2" style="opacity: 0.3; color: #70c542">
              Average network wait time is 60 seconds.
            </small>
          </div>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import BigNumber from "bignumber.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "ModalWindow",
  data: function () {
    return {
      show: false,
      id: null,
      loading: false,
      sum: 0,
      sums: [
        {
          sumId: "12000",
        },
        {
          sumId: "50000",
        },
        {
          sumId: "150000",
        },
        {
          sumId: "200000",
        },
      ],
    };
  },
  computed: {
    info() {
      return this.presaleService.info;
    },
  },
  components: {
    Loading,
  },
  methods: {
    closeModal: function () {
      this.show = false;
    },
    check: function () {
      console.log(this.sum);
    },
    async buy() {
      this.loading = true;
      try {
        await this.presaleService.loginAndBuy(
          new BigNumber(this.sum).shiftedBy(6)
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.circular-loader {
  display: block;
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
}
.loading {
  position: relative;
  display: block;
}
.loading .circular-loader {
  max-width: 20px;
}
.loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #70c542;
  }
  40% {
    stroke: #70c542;
  }
  66% {
    stroke: #70c542;
  }
  80%,
  90% {
    stroke: #70c542;
  }
}
@keyframes color {
  0% {
    stroke: #70c542;
  }
  40% {
    stroke: #70c542;
  }
  66% {
    stroke: #70c542;
  }
  80%,
  90% {
    stroke: #70c542;
  }
}
.info {
  background: url(/images/info.svg) 1em center no-repeat, rgba(0, 0, 0, 0.1);
  background-size: 2em;
  border-radius: 20px;
  font-size: 0.9em;
  padding: 1em 1em 1em 4em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.7);
}
.modal--footer input[type="number"] {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  outline: none;
  color: #fff;
  font-size: 18pt;
  width: 80%;
}
.select-sum input[type="radio"] {
  display: none;
}
.select-sum label {
  cursor: pointer;
  background: #15181e;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}
.select-sum input[type="radio"]:checked + label {
  background-image: linear-gradient(to right, #3e91f1 0%, #3089f0 100%);
  color: #fff;
}
.select-sum label:hover {
  background-color: #13161c;
}
.modal-shadow {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;
}
.modal-presale {
  background: #1b1e25 url(/images/header1.png) top right no-repeat;
  background-size: 50%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 1);
  border-radius: 20px;
  padding: 30px;
  min-width: 420px;
  max-width: 580px;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}
.modal_close {
  position: absolute;
  content: "";
  right: 10px;
  top: 20px;
  background: none;
  max-width: 24px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
