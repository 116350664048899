import App from "./App.vue";
import { createApp } from "vue";
import { initPresale } from "./services/PresaleService";

function resolveNetName() {
  window.netName = "mainnet";
  const testnetFlag = new URLSearchParams(window.location.search).get(
    "testnet"
  );
  if (testnetFlag && testnetFlag === "true") {
    window.netName = "testnet";
  }
  //if (window.location.href.indexOf("localhost") > -1) {
  //  window.netName = "localnet";
  //}
}

async function init() {
  resolveNetName();
  let refAddress = new URLSearchParams(window.location.search).get('ref');
  if (refAddress !== undefined && refAddress !== null) {
    localStorage.setItem('ref', refAddress);
  } else {
    refAddress = localStorage.getItem('ref');
  }
  const presaleService = await initPresale(window.netName, refAddress);

  const app = createApp(App);
  app.config.globalProperties.window = window;
  app.config.globalProperties.presaleService = presaleService;
  app.mount("#app");
}

init();

var countDownDate = new Date("2021-11-30T15:00:00.000Z").getTime();
var x = setInterval(function () {
  var now = new Date().getTime();
  var distance = countDownDate - now;
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  document.getElementById("countdown").innerHTML =
    days + ":" + hours + ":" + minutes + ":" + seconds + "";
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("countdown").innerHTML = "ERROR";
  }
}, 1000);
