<template>
  <transition name="fade">
    <div v-if="show" class="modal-shadow" @click.self="closeModal">
      <div class="modal-presale">
        <slot name="title">
          <h3 class="modal--title">Work in progress...</h3>
          <button class="modal_close" @click="closeModal">
            <img src="images/cancel.svg" />
          </button>
        </slot>
        <slot name="body">
          <img class="d-block w-75 mt-3 mx-auto" src="images/under.png" />
          <span class="d-block text-center mt-4"
            ><img
              style="max-width: 20px; margin-right: 10px"
              src="images/warning.svg"
            />This page is under construction...</span
          >
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalWindowWorks",
  data: function () {
    return {
      show: false,
    };
  },
  methods: {
    closeModal: function () {
      this.show = false;
    },
    check: function () {
      console.log(this.sum);
    },
  },
};
</script>
