import { TezosToolkit } from "@taquito/taquito";
import BigNumber from "bignumber.js";
import { InMemorySigner } from "@taquito/signer";
import { BeaconWallet } from "@taquito/beacon-wallet";

export class PresaleService {
  constructor(conf, upline) {
    this.conf = conf;
    this.upline = upline;
    this.tezos = new TezosToolkit(conf.network.rpc);
    const options = {
      name: "Control Presale",
      preferredNetwork: conf.network.type,
    };
    this.wallet = new BeaconWallet(options);
    this.tezos.setWalletProvider(this.wallet);
    this.info = {
      startedAt: null,
      exch_price: new BigNumber(100),
      out_balance: new BigNumber(0),
      sold: new BigNumber(0),
      referal_bonus: new BigNumber(0),
    };
    this.getCurrentAccount().then(a => {
      this.accountAddress = a.address;
    })
  }

  async init() {
    this.tezos.setSignerProvider(
      await InMemorySigner.fromSecretKey(
        "edsk3QoqBuvdamxouPhin7swCvkQNgq4jP5KZPbwWNnwdZpSpJiEbq"
      )
    );
    try {
      this.contract = await this.tezos.contract.at(this.conf.presaleContract);
      await this.refreshInfo();
    } catch (e) {
      console.error(e)
    }
    return this;
  }

  async getCurrentAccount() {
    return await this.wallet.client.getActiveAccount();
  }

  async getCurrentAccountAddress() {
    const account = await this.wallet.client.getActiveAccount();
    return account?.address;
  }

  async logout() {
    await this.wallet.clearActiveAccount();
    this.accountAddress = null;
  }

  async login() {
    this.wallet.clearActiveAccount();
    const netParams = {
      name: this.conf.network.name,
      rpcUrl: this.conf.network.rpc,
      type: this.conf.network.type,
    };
    console.log(`Loggin with params: ${JSON.stringify(netParams)}`);
    await this.wallet.requestPermissions({
      network: netParams,
    });
    this.accountAddress = await this.wallet.client.getActiveAccount()?.address;
    console.log(`Account address is ${this.accountAddress}`)
    return await this.wallet.client.getActiveAccount();
  }

  async buy(amt) {
    const tzRequired = amt.div(this.info.in2OutExchangePrice).decimalPlaces(0);
    console.log(`Mutez required: ${tzRequired}`);

    const op = await (
      await this.tezos.wallet.at(this.conf.presaleContract)
    ).methods
      .buy(tzRequired.toString(), this.upline ? this.upline : null)
      .send({ amount: tzRequired, mutez: true });
    return await op.confirmation();
  }

  async loginAndBuy(amt, upline) {
    await this.logout();
    await this.login();
    await this.buy(amt, upline);
  }

  async refreshInfo() {
    const contractStorage = await this.contract.storage();
    this.info = {
      startedAt: contractStorage.started_at
        ? new Date(contractStorage.started_at)
        : null,
      in2OutExchangePrice: new BigNumber(
        contractStorage.config.exch_price
      ).decimalPlaces(6),
      out2InExchangePrice: new BigNumber(1)
        .div(new BigNumber(contractStorage.config.exch_price))
        .decimalPlaces(6),
      out_balance: new BigNumber(contractStorage.out_balance)
        .shiftedBy(-6)
        .decimalPlaces(6),
      sold: new BigNumber(contractStorage.sold).shiftedBy(-6).decimalPlaces(6),
      referal_bonus: new BigNumber(contractStorage.referal_bonus).shiftedBy(-6).decimalPlaces(6),
    };
    console.log(`Info updated: ${JSON.stringify(this.info)}`);
    return this.info;
    //setTimeout(() => this.refreshInfo(), 10000);
  }
}

export const configs = {
  testnet: {
    network: {
      name: "Control Presale",
      type: "granadanet",
      rpc: "https://granadanet.smartpy.io",
    },
    presaleContract: "KT1HxoPrfNqvsUiGWPdQdxouaNuDieMx4w82",
    inToken: {
      type: "tz",
    },
    outToken: {
      type: "fa2",
      address: "KT1LispZPuRAPN4ygurL1cHg2HqhpSoxLEje",
      fa2Id: 0,
    },
  },
  mainnet: {
    network: {
      name: "Main Node",
      type: "mainnet",
      rpc: "https://teznode.letzbake.com",
    },
    presaleContract: "KT1PYxZU6DBftNsMUpcS9YuRSer44xiL122F",
    inToken: {
      type: "tz",
    },
    outToken: {
      type: "fa2",
      address: "KT1P456X7TN1QMu7xNdrDVMBJwNpraFkNdGx",
      fa2Id: 0,
    },
  },
};
export async function initPresale(netName, upline) {
  const conf = configs[netName];
  const presaleService = await new PresaleService(conf, upline).init();
  return presaleService;
}
