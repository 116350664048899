<template>
  <header>
    <div class="container-xxl">
      <div class="row py-5 d-flex justify-content-between top-menu">
        <div class="col-md-4">
          <a class="logo" href="/"
            ><img src="images/logo.png" />Control finance</a
          >
        </div>
        <div class="nav col-md-4 d-flex justify-content-end">
          <ul>
            <li><a href="#how">How to earn</a></li>
            <li><a href="#swap">Swap tokens</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-xxl">
      <div class="row align-items-center position-relative py-10">
        <div class="col-md-6 my-5">
          <div class="col header-text">
            <span class="d-block mb-3"
              >Earn money, save it and multiply it-
              <span>the way to financial freedom</span></span
            >
            <h1>A place where<br /><span>assets work</span><br />for you</h1>
            <span class="d-block mt-5"
              >The Control Finance project is being developed by the FlameDeFi team and will be an integral part of the rapidly developing FlameDeFi ecosystem.</span
            >
            <div class="mt-5">
           <!--   <a @click="showModalWorks" class="button-medium">App (soon) <img src="images/app.svg"/></a> -->
              <a class="button-medium" href="https://app.controlfin.net">App <img src="images/app.svg"/></a>
              
              <modal-windowWorks ref="modalWorks"></modal-windowWorks>
              <a href="https://medium.com/@ControlFinance/control-finance-3fc5b8d93155" class="button-medium button-grey ms-3"
                >Read more &rarr;</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 my-5 d-flex justify-content-end features-md">
          <div class="features col-10">
            <ul>
              <li>
                <img src="images/protocol.png" />
                <div>
                  <h4>Automatic rates</h4>
                  <span class="d-block mt-3"
                    >A unique automatic mechanism for regulating interest rates
                    on lendings and borrows will allow to exclude the case when
                    some asset is not enough.</span
                  >
                </div>
              </li>
              <li>
                <img src="images/mind.png" />
                <div>
                  <h4>Governance</h4>
                  <span class="d-block mt-3"
                    >The Control token is a governance token. CTRL holders will
                    have the opportunity to participate in voting on changes in
                    the Control Hub system.</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
        <a href="#" class="scroll-down">
          <img src="images/scroll-bg.svg" />
          <img src="images/down-arrow.svg" class="down-arrow" />
        </a>
      </div>
    </div>
  </header>
  <div class="padding-5" id="how">
    <div class="about-us">
      <div class="container-xxl">
        <div class="row justify-content-between">
          <div class="col-md-7">
            <h1 class="d-block mb-5 position-relative about-main-title">
              About and How to
            </h1>
            <div class="d-flex align-items-center about-md">
              <img class="w-25 me-5" src="images/ab1.png" />
              <div>
                <span class="d-block"
                  >Users will be able to provide their Tezos assets for Control
                  Hub and earn a variable interest rate. Interest is accrued for
                  each Tezos block or approximately once a half minute, and users can
                  withdraw their principal amount plus interest at any
                  time.</span
                >
                <span class="d-block mt-5">
                  Control Hub users can borrow crypto assets using any other
                  supported asset as collateral. This gives them the flexibility
                  to make a trade or the opportunity to diversify their
                  portfolio by borrowing an asset that they did not own before.
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4 how-to">
            <ul>
              <li class="d-flex">
                <h2>1.</h2>
                <span class="d-block ms-4"
                  >Submit your asset on the Tezos blockchain to the Control Hub
                  system and start earning a variable interest rate instantly,
                  in a minute your asset will start generating income.</span
                >
              </li>
              <li class="d-flex">
                <h2>2.</h2>
                <span class="d-block ms-4"
                  >In return, you will receive a sToken, the price of which
                  will constantly increase in accordance with the variable
                  interest rate.
                </span>
              </li>
              <li class="d-flex">
                <h2>3.</h2>
                <span class="d-block ms-4"
                  >When you want to take your asset back along with the profit,
                  just return the sToken to the system.
                </span>
              </li>
              <li class="d-flex">
                <h2>4.</h2>
                <span class="d-block ms-4"
                  >In addition to the income from the placement of the asset,
                  you will receive Control tokens depending on your
                  activity.</span
                >
              </li>
              <li class="d-flex">
                <h2>5.</h2>
                <span class="d-block ms-4"
                  >Any asset placed by you automatically becomes collateral and
                  you can borrow a loan depending on the established Collateral
                  Factor. Borrowers also receive income in Control tokens.</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div v-if="false && info.startedAt !== null " class="presale-l">
      <div v-if="!info.out_balance.isZero()" class="container-xxl">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-6 d-flex align-items-center buy-md">
            <h2 class="col-md-6 me-4">Join the Control DAO token Presale</h2>
            <div class="buy-md-but">
              <button @click="showModal" class="button-medium" :disabled="info.startedAt === null" >Buy CTRL tokens</button>
              <modal-window ref="modal"></modal-window>
            </div>
            
          </div>
          <div class="col-md-5 info-md">
            <div class="info">
              You will be able to sell or add CTRL tokens to the FlameDEX and Quipuswap
              liquidity pools after the sale is completed. During the sale, only
              staking in Cold Farm is allowed.
            </div>
          </div>
          <div v-if="accountAddress">Your ref link: <bold>{{window.location.protocol}}//{{window.location.host}}?ref={{accountAddress}}</bold></div>
        </div>
      </div>
      <div v-else class="container-xxl">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-6">
            <div class="d-block">
              <img class="mb-2" src="images/price.png" />
              <h4>Current price:</h4>
              <h3>1 CTRL = {{info.out2InExchangePrice.decimalPlaces(4).toString()}} XTZ</h3>
              <small>* effective price will be based on rate 1 XTZ={{info.in2OutExchangePrice.toString()}} CTRL</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="info">
              <div>
                <h3>The presale is over.</h3>
                Follow our news on our social networks and also follow our
                roadmap.
              </div>
              <div class="social-links mt-3">
                <ul class="d-flex">
                  <li>
                    <a href="https://discord.gg/cz4zXp7Rsh" target="_blank"
                      ><img src="images/discord.svg"
                    /></a>
                  </li>
                  <li>
                    <a href="https://github.com/flamedefi" target="_blank"
                      ><img src="images/github.svg"
                    /></a>
                  </li>
                  <li>
                    <a href="https://medium.com/@ControlFinance/control-finance-3fc5b8d93155" target="_blank"
                      ><img src="images/medium.svg"
                    /></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/controlfinnet" target="_blank"
                      ><img src="images/twitter.svg"
                    /></a>
                  </li>
                  <li>
                    <a href="https://t.me/FLAMEtokenFarm" target="_blank"
                      ><img src="images/telegram.svg"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="presale py-5">
        <div class="container-xxl">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="d-flex align-items-center">
                <div>
                  <span>Currently sold:</span>
                  <h2>{{ info.sold.plus(info.referal_bonus).toString() }} CTRL</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-end sup-md">
              <div>
                <span>Tokens in sale:</span>
                <h2>{{ info.out_balance.toString() }} CTRL</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="progress-folder">
          <h1
            class="increasing_pct full button-grey py-2 px-4"
            v-if="increasing_pct >= 90"
            :style="{ right: 0, marginRight: 12 + 'px' }"
          >
            {{ increasing_pct.toFixed(2) }}%
          </h1>
          <h1
            class="increasing_pct button-grey py-2 px-4"
            v-else-if="increasing_pct <= 5"
            :style="{ left: 0, marginLeft: 12 + 'px' }"
          >
            {{ increasing_pct.toFixed(2) }}%
          </h1>
          <h1
            class="increasing_pct button-grey py-2 px-3"
            v-else
            :style="{ left: `${increasing_pct}%` }"
          >
            {{ increasing_pct.toFixed(2) }}%
          </h1>
          <div class="vue-simple-progress">
            <div
              class="vue-simple-progress-bar"
              :style="{ width: `${increasing_pct}%` }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-token">
      <div class="container-xxl">
        <div class="row align-items-center">
          <div class="col-md-4">
            <img class="w-50 mb-4" src="images/graph.png" />
            <h2>Technical information about CTRL token</h2>
            <span><small>*All unsold tokens will be transfered to the next round of sale. The team's tokens will be locked for 2 years with a monthly unlock.</small></span>
          </div>
          <div class="col-md-5">
            <div class="info token-char">
              <ul>
                <li><span>Initial token supply:</span> 100`000`000 CTRL</li>
                <li><span>Maximum token supply:</span> 100`000`000 CTRL</li>
                <li><span>Seed sale:</span> 20`000`000 CTRL*</li>
                <li><span>Team:</span> 10`000`000 CTRL*</li>
                <li><span>Airdrop:</span> 1`000`000 CTRL</li>
                <li><span>Marketing:</span> 4`000`000 CTRL</li>
                <li>
                  <span>Smart contract:</span> <small class="md-d-block"><a :href="'https://better-call.dev/' + conf.network.type + '/' + conf.outToken.address">{{conf.outToken.address}}</a></small>
                </li>
                <li><span>Token ID:</span> 0</li>
                <li><span>Decimals:</span> 6</li>
              </ul>
            </div>
          </div>
     <!--  <div v-if="info.startedAt === null" class="col-md-3">
            <span class="d-block">Time left to presale:</span>
            <h2
              class="countdown button-grey py-2 px-3 mt-2"
              id="countdown"
            ></h2>
          </div>
      -->
        </div>
      </div>
    </div>
  </div>
  <section class="roadmap mt-5 pt-5">
    <div class="container-xxl">
      <div class="row">
        <h1 class="d-block mb-5 position-relative roadmap-main-title">
          Roadmap
        </h1>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row pb-5">
        <div class="col-md-3 py-5 text-center border-end position-relative">
          <img class="img1" src="images/presale.png" />
          <h1 class="quartal">Q4<small>2021</small></h1>
          <h3 class="d-block pt-4 roadmap-title">Control DAO token Presale</h3>
          <h1 class="road-percent d-block my-3 pt-5">
            {{ road1.toLocaleString() }}%
          </h1>
          <div
            :style="{ bottom: `${road1}%` }"
            class="roadmap-progress-folder d-flex justify-content-center mb-5"
          >
            <div class="roadmap-progress" :style="{ width: `${road1}%` }"></div>
          </div>
          <span class="d-block mx-auto"
            >During the Presale, we will sell a limited number of CTRL tokens.
            The presale will be held from November 30 to December 02, 2021.</span
          >
          <div class="bg-progress" :style="{ height: `${road1}%` }"></div>
        </div>
        <div class="col-md-3 py-5 text-center border-end position-relative">
          <img class="img2" src="images/launch.png" />
          <h1 class="quartal">Soon<small>2022</small></h1>
          <h3 class="d-block pt-4 roadmap-title">Control Hub<br />launches</h3>
          <h1 class="road-percent d-block my-3 pt-5">
            {{ road2.toLocaleString() }}%
          </h1>
          <div
            :style="{ bottom: `${road2}%` }"
            class="roadmap-progress-folder d-flex justify-content-center mb-5"
          >
            <div class="roadmap-progress" :style="{ width: `${road2}%` }"></div>
          </div>
          <span class="d-block mx-auto"
            >Launch of the Control Hub with the possibility of lending and
            borrowing assets.</span
          >
          <div class="bg-progress" :style="{ height: `${road2}%` }"></div>
        </div>
        <div class="col-md-3 py-5 text-center border-end position-relative">
          <img class="img3" src="images/launch2.png" />
          <h1 class="quartal">Q4<small>2022</small></h1>
          <h3 class="d-block pt-4 roadmap-title">
            Fully community managed project
          </h3>
          <h1 class="road-percent d-block my-3 pt-5">
            {{ road3.toLocaleString() }}%
          </h1>
          <div
            :style="{ bottom: `${road3}%` }"
            class="roadmap-progress-folder d-flex justify-content-center mb-5"
          >
            <div class="roadmap-progress" :style="{ width: `${road3}%` }"></div>
          </div>
          <span class="d-block mx-auto"
            >Control token holders able to vote for new assets in Control Hub,
            Deprecate markets, Collateral factors and others.</span
          >
          <div class="bg-progress" :style="{ height: `${road3}%` }"></div>
        </div>
        <div class="col-md-3 py-5 text-center position-relative">
          <img class="img4" src="images/presale2.png" />
          <h1 class="quartal">Q4<small>2022</small></h1>
          <h3 class="d-block pt-4 roadmap-title">Lending protocol aggregator</h3>
          <h1 class="road-percent d-block my-3 pt-5">
            {{ road4.toLocaleString() }}%
          </h1>
          <div
            :style="{ bottom: `${road4}%` }"
            class="roadmap-progress-folder d-flex justify-content-center mb-5"
          >
            <div class="roadmap-progress" :style="{ width: `${road4}%` }"></div>
          </div>
          <span class="d-block mx-auto"
            >We will combine all the lending protocols in one place so that users always have the opportunity to choose the most favorable conditions.</span
          >
          <div class="bg-progress" :style="{ height: `${road4}%` }"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ModalWindow from "./components/modal.vue";
import ModalWindowWorks from "./components/modalWorks.vue";

export default {
  name: "App",
  created() {
    setInterval(async () => {
      this.info = await this.presaleService.refreshInfo();
      this.accountAddress = await this.presaleService.getCurrentAccountAddress();
    }, 10000);
  },
  data: function () {
    return {
      counting: false,
      random_pct: 0,
      decreasing_pct: 100,
      totalCTRL: 20000000,
      soldCTRL: 0,
      road1: 90,
      road2: 95,
      road3: 48,
      road4: 32,
      info: this.presaleService.info,
      accountAddress: this.presaleService.accountAddress,
    };
  },
  components: {
    ModalWindow,
    ModalWindowWorks,
  },
  methods: {
    showModal: function () {
      this.$refs.modal.show = true;
    },
    showModalWorks: function () {
      this.$refs.modalWorks.show = true;
    },
    sendDataFunction: function () {},
    startCountdown: function () {
      this.counting = true;
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
    ps() {
      return this.presaleService;
    },
  },
  computed: {
    increasing_pct() {
      if (this.info.out_balance.isZero()) return 100;
      return (
        (parseInt(this.info.sold.toNumber()) * 100) /
        parseInt(this.info.out_balance.toNumber())
      );
    },
    conf() {
      return this.presaleService.conf;
    }
  },
};
</script>
